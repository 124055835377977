/**
 * Styles: Cart drawer overlay
 * -----------------------------------------------------------------------------
 * Override styles for cart drawer overlay.
 *
 */
.cartDrawerOverlay {
  @include mq($until: m) {
    top: var(--header-height-visible);
  }
}
