.signUpPopup {
  .modal {
    &[data-reach-dialog-content] {
      max-width: 886px;
      margin: auto !important;
      @media screen and (max-width: 1024px) {
        width: 90vw;
      }
    }
  }

  .overlay {
    &__container {
      width: 886px;
      padding: 0px;
      height: fit-content;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    &__body {
      margin-top: 0;
    }
  }
}
