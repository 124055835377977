/**
 * Styles: Modal.
 * -----------------------------------------------------------------------------
 * Specific styles for modal overlays.
 *
 */

.modal {
  &__reach-overlay[data-reach-dialog-overlay] {
    align-items: end;
    display: grid;
  }

  &[data-reach-dialog-content] {
    width: 100%;
    margin: unset;
    background: white;
    padding: 0;
    outline: none;
    place-items: center;
  }

  &__container {
    background-color: var(--color-background-light);
    padding: var(--spacing-xl);
    pointer-events: auto;
  }

  &__close {
    position: absolute;
    right: var(--spacing-xs);
    top: var(--spacing-xs);
  }

  /**
   * Media queries.
   */
  @include mq($from: l) {
    &__reach-overlay[data-reach-dialog-overlay] {
      align-items: center;
    }

    &[data-reach-dialog-content] {
      max-width: 656px;
      margin: auto;
    }

    &__container {
      padding: var(--spacing-3xl);
    }

    &__close {
      right: var(--spacing-m);
      top: var(--spacing-m);
    }

    &#{&}--wide {
      &[data-reach-dialog-content] {
        max-width: 100%;
      }
    }
  }
}
