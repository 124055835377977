/**
 * Styles: Menu drawer overlay
 * -----------------------------------------------------------------------------
 * Override styles for menu drawer overlay.
 *
 */
.menuDrawerOverlay {
  height: calc(var(--viewport-height) - var(--header-height-visible));
  top: var(--header-height-visible);
  z-index: var(--layer-heightened);
  overflow: hidden;

  .overlay {
    &__container {
      padding: 0;
      overflow: hidden;
      width: 90vw;
    }
  }

  [aria-label='menuDrawer'] {
    background-color: unset;
    width: 90vw;
  }
}
