@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Overlay
 * -----------------------------------------------------------------------------
 * Base styles for overlays (default styles for drawers and modals).
 *
 */
@import './styles/window-overlay';

.overlay {
  $parent: &;
  display: block;
  z-index: var(--layer-overlay);
  margin: 0;

  &__reach-overlay[data-reach-dialog-overlay] {
    display: grid;
    z-index: var(--layer-window-overlay);
  }

  &__container {
    max-height: 100%;
    overflow-y: auto;
    padding: var(--spacing-m);
    position: relative;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    margin-block-start: var(--spacing-l);
  }

  &__close {
    @include button-reset;
  }

  /**
   * Media queries.
   */
  @include mq($from: l) {
    &__close {
      margin: calc(var(--spacing-m) * -1);
      padding: var(--spacing-m);
    }
  }
}

/**
 * Import overriding type styles.
 */
/*! purgecss start ignore */
@import './styles/custom-overlay';
@import './styles/drawer';
@import './styles/mobile-drawer';
@import './styles/modal';
/*! purgecss end ignore */

/**
 * Import component-specific styles.
 */
@import './styles/custom/cart-drawer-overlay';
@import './styles/custom/menu-drawer-overlay';
@import './styles/custom/search-menu-overlay';
@import './styles/custom/store-selector-overlay';
@import './styles/custom/gallery-zoom-overlay';
@import './styles/custom/recommended-essentials-overlay';
@import './styles/custom/quick-shop-overlay';
@import './styles/custom/sign-up-popup-overlay';
