/**
 * Styles: Quick Shop overlay
 * -----------------------------------------------------------------------------
 * Override styles for the Quick Shop overlay.
 *
 */
.quickShopOverlay {
  .overlay {
    &__header,
    &__body {
      margin: 0;
    }

    &__header {
      padding: var(--spacing-l);
    }

    &__body {
      display: flex;
      height: 100%;
      padding: 0;
      padding-block-start: 0;
    }
  }

  @include mq($until: l) {
    .overlay {
      &__header {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-m);

        p {
          order: 2;
          width: 100%;
        }
      }
    }
  }

  @include mq($from: l) {
    .overlay {
      &__body {
        padding-block-start: var(--spacing-xs);
      }
    }
  }
}
