/**
 * Styles: Drawer
 * -----------------------------------------------------------------------------
 * Specific styles for drawer overlays.
 *
 */
.drawer {
  background-color: var(--color-background-light);
  height: var(--viewport-height);
  left: 0;
  max-width: 480px;
  top: 0;
  width: 100%;
  padding: 0;

  &__container {
    height: 100%;
    min-height: 100%;
    padding: 0;

    > div {
      height: 100%;
    }
  }

  &__body {
    display: flex;
    max-height: 100%;
    min-height: 100%;

    > div,
    > div:not([class]) > div {
      width: 100%;
    }

    > div:not(.critical-component-hide),
    > div:not([class]) > div:not(.critical-component-hide) {
      display: flex;
    }
  }

  /**
   * Modifiers.
   */
  &#{&}--left {
    left: unset;
    right: 0;
    margin-left: auto;
  }

  &--left[data-reach-dialog-overlay] {
    z-index: var(--layer-window-overlay);
  }

  &#{&}--wide {
    max-width: 100%;
  }

  /**
   * Media queries.
   */
  @include mq($from: l) {
    top: 0;

    &__container {
      padding: 0;
      padding-top: var(--spacing-l);
    }
  }
}
