.mobile-drawer {
  $parent: &;
  align-items: end;
  background-color: transparent;
  display: grid;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: min(480px, 100vw);
  pointer-events: none;
  padding: 0;
  top: 0;
  width: 100%;

  &__reach-overlay[data-reach-dialog-overlay] {
    display: grid;
    overflow: hidden;
  }

  &__container {
    background-color: var(--color-background-light);
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 80vh;
    overflow: visible;
    padding: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
    max-width: inherit;
  }

  &[data-transitioned='true'] {
    #{$parent}__container {
      &::after {
        background-color: var(--color-background-light);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
      }
    }
  }

  &__header {
    margin: 0 var(--spacing-m);
  }

  &__handle {
    @include button-reset;
    padding: var(--spacing-s) 0;
    position: relative;
    width: 100%;

    &::after {
      background: var(--color-light-grey);
      border-radius: 30px;
      content: '';
      height: 5px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 65px;
    }
  }

  &__close {
    display: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 0 var(--spacing-m);
    padding: var(--spacing-m) 0;
  }

  &__footer {
    padding: 0 var(--spacing-m);
  }

  @include mq($from: l) {
    margin: 0;

    &__container {
      border-radius: 0;
      height: 100vh;
      max-height: var(--viewport-height);
    }

    &__header {
      display: flex;
      margin: 0;
      padding: var(--spacing-m) var(--spacing-l);
    }

    &__handle {
      display: none;
    }

    &__close {
      display: block;
      margin: 0;
      margin-inline-start: auto;
      padding: var(--spacing-2xs) 0;

      :global(.icon) {
        height: var(--icon-2xs);
        width: var(--icon-2xs);
      }
    }

    &__body {
      margin: 0 var(--spacing-2xl);
      padding: 0 0 var(--spacing-2xl);
    }

    &__footer {
      padding: 0 var(--spacing-2xl) var(--spacing-2xl);
    }

    /**
     * Modifiers.
     */
    &#{&}--left {
      left: unset;
      right: 0;
      margin-left: auto;
    }
  }
}

/**
 * Mobile Filters Overrides.
 */

.mobileFiltersOverlay {
  &__body {
    margin: 0;
  }
}
