/**
 * Styles: Product Gallery Zoom overlay
 * -----------------------------------------------------------------------------
 * Override styles for the product gallery zoom overlay.
 *
 */
.productGalleryZoomOverlay {
  max-width: unset;

  .overlay {
    max-width: unset;

    &__container {
      padding: var(--spacing-10xl) var(--spacing-7xl);

      img {
        height: auto;
      }
    }
  }
}
