@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Color Selector Tab Component
 * -----------------------------------------------------------------------------
 * Base styles for the Color Selector Tab component.
 *
 */
.colorSelectorTab {
  $parent: &;
  --shade-grid-height: 20%;
  --shade-grid-width: 12.5%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-width: 200%;

  &__hide {
    display: none;
  }

  &__noOverflow {
    &#{$parent}__container {
      overflow: hidden;
    }
  }

  &__container {
    overflow: auto;
    overflow-y: hidden;
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover #{$parent}__description,
    &:hover #{$parent}__helpText {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__containerPlaceholder {
    min-height: 400px;
  }

  &__description {
    @include text-heading-xl;
    @include fluid-type(42rem, 61rem, 1.875rem, 4.6875rem);
    animation: fade-in var(--timing-slow);
    left: 55%;
    line-height: 1em;
    margin: 0;
    opacity: 1;
    position: absolute;
    text-indent: -10%;
    transform: translate(-50%, -50%);
    transition: all var(--timing-normal) ease-in-out;
    top: 50%;
    white-space: pre-line;
    width: 68%;
    z-index: var(--layer-heightened);
  }

  &__helpText {
    bottom: var(--spacing-2xl);
    display: none;
    left: 50%;
    opacity: 1;
    position: absolute;
    transform: translateX(-50%);
    z-index: var(--layer-heightened);
  }

  &__surpriseButton {
    position: absolute;
    bottom: var(--spacing-xl);
    right: var(--spacing-xl);
    z-index: var(--layer-heightened);
  }

  @include mq($from: m) {
    margin-inline-end: calc(-1 * var(--margin-tablet));
    margin-inline-start: calc(-1 * var(--margin-tablet));
  }

  @include mq($from: m) {
    --shade-grid-height: 25%;
    --shade-grid-width: 10%;
    min-width: unset;
    width: unset;

    &__container {
      overflow: unset;
    }

    &__description {
      width: 42%;
    }

    &__helpText {
      display: block;
    }
  }

  @include mq($from: l) {
    &__surpriseButton {
      right: var(--spacing-5xl);
    }
  }
}

/**
 * Text Fade In animation.
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
