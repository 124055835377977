@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Inline Product Card Component
 * -----------------------------------------------------------------------------
 * Base styles used in:
 *   - Inline Product Card Component.
 *   - Recommended Essential Card Component.
 *
 */
.inlineProductCard {
  $parent: &;

  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: var(--spacing-m);
  padding: var(--spacing-l) 0;

  &__image {
    height: auto;
    width: 57px;
  }

  &__badge {
    margin: 0 0 var(--spacing-xs);
  }

  &__showDetails {
    /* Add more specificity weight to styles */
    &#{&}#{&} {
      display: block;
      text-decoration: underline;
    }
  }

  &__slogan {
    padding-block-end: var(--spacing-3xs);
    padding-block-start: var(--spacing-3xs);
  }

  /**
    * Loading state.
    */
  &:global(.is-loading) {
    #{$parent}__imageWrapper,
    #{$parent}__systemName,
    #{$parent}__title,
    #{$parent}__price {
      @include loading();
    }

    #{$parent}__systemName,
    #{$parent}__title {
      width: 60%;
      white-space: nowrap;
    }

    #{$parent}__image {
      @include aspect-ratio($force: true);
      // 775 / 1000 aspect ratio
      --ratio-percent: calc(1000 / 775 * 100%);
    }

    #{$parent}__price {
      margin: 0;
      width: 40%;
    }

    #{$parent}__loadingReviews {
      @include loading();
      width: 50%;
      min-height: 16px;
    }

    #{$parent}__quickAdd#{$parent}__quickAdd {
      @include loading();
      border-radius: 80px;
    }
  }

  @include mq($from: l) {
    gap: calc(var(--spacing-m) + var(--spacing-2xs));

    &__badge {
      margin-block-end: var(--spacing-s);
    }

    &__systemName {
      margin-block-end: var(--spacing-2xs);
    }

    &__title {
      margin-block-end: var(--spacing-xs);
    }
  }
}
