@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Color Selector Shade Component
 * -----------------------------------------------------------------------------
 * Base styles for the Color Selector Shade component.
 *
 */
.colorSelectorShade {
  $parent: &;
  @include aspect-ratio($force: false);
  // 1 / 1 aspect ratio
  --ratio-percent: 100%;
  --shade-grid-height: 20%;
  --shade-grid-width: 12.5%;
  --shade-color: #d3d3d3;
  background-color: var(--shade-color);
  color: var(--color-white);
  cursor: pointer;
  position: initial;
  width: var(--shade-grid-width);

  &[data-theme='dark'] {
    color: black;
  }

  &__button {
    @include button-reset;
    @include aspect-ratio($force: false);
    // 1 / 1 aspect ratio
    --ratio-percent: 100%;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
    height: var(--shade-grid-height);
    position: absolute;
    width: var(--shade-grid-width);
    z-index: var(--layer-raised);

    &#{&}#{&}:hover,
    &#{&}#{&}:focus,
    &#{&}#{&}:focus-visible {
      box-shadow: 3px 3px 24px rgb(0 0 0 / 45%);
      outline: none;
      transition: box-shadow var(--timing-normal) var(--easing-normal);
    }
  }

  &__infoContainer {
    background-color: var(--shade-color);
    height: var(--shade-grid-height);
    margin: 0;
    position: absolute;
    left: var(--left);
    top: var(--top);
    transition: all var(--timing-normal) var(--easing-normal);
    width: var(--shade-grid-width);
    z-index: var(--layer-flat);

    &:global(.expand) {
      height: 100%;
      outline: none;
      left: var(--expand-left);
      top: 0;
      width: 50%;
      z-index: var(--layer-heightened);

      &:hover,
      &:focus,
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }
  }

  &__info {
    height: 100%;
    opacity: 0;
    position: relative;
    transition: all var(--timing-slow) var(--easing-normal);
    visibility: hidden;

    &:global(.visible) {
      opacity: 1;
      visibility: visible;
    }

    &[data-theme='dark'] {
      color: black;
    }

    &[data-theme='light'] {
      color: white;
    }
  }

  &__backgroundImage {
    z-index: -1;
    object-fit: cover;
  }

  &__packshotImage {
    margin: auto;
    max-width: 60%;
    object-fit: contain;
    width: auto;
    min-height: 0;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: var(--spacing-xl);
  }

  &__detailsContent {
    margin-block-start: auto;
  }

  &__detailsTitle {
    margin-bottom: var(--spacing-3xs);
  }

  &__detailsAvailableIn {
    margin-bottom: var(--spacing-m);
  }

  &__detailsButton {
    /* Add more specificity weight to styles */
    &#{&}#{&} {
      width: fit-content;
    }
  }

  &__close {
    @include button-reset;
    position: absolute;
    padding: 20px;
    right: 0;
  }

  @include mq($from: m) {
    --shade-grid-height: 25%;
    --shade-grid-width: 10%;

    &__infoContainer:global(.expand) {
      width: 40%;
    }
  }
}
