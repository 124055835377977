/**
 * Styles: Store selector overlay
 * -----------------------------------------------------------------------------
 * Override styles for the store selector overlay.
 *
 */
.storeSelectorOverlay {
  @include mq($until: l) {
    .overlay {
      &__header,
      &__body {
        margin: 0 var(--margin-mobile);
      }

      &__body {
        padding-block-end: var(--spacing-3xl);
        padding-block-start: var(--spacing-l);
      }
    }
  }
}
