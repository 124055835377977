/**
 * Styles: Recommended Essentials overlay
 * -----------------------------------------------------------------------------
 * Override styles for the Recommended Essentials overlay.
 *
 */
.recommendedEssentialsOverlay {
  .overlay {
    &__header,
    &__body {
      margin: 0;
    }

    &__header {
      padding-block-end: 0;
      padding-block-start: var(--spacing-s);
      padding-inline-end: var(--spacing-l);
      padding-inline-start: var(--spacing-l);
    }

    &__body {
      display: flex;
      height: 100%;
      padding: 0;
      padding-block-start: 0;
    }

    &__footer {
      background-color: var(--color-background-grey);
      padding-inline-end: var(--spacing-l);
      padding-inline-start: var(--spacing-l);
    }
  }

  @include mq($until: l) {
    .overlay {
      &__header {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-m);

        p {
          order: 2;
          width: 100%;
        }
      }
    }
  }

  @include mq($from: l) {
    .overlay {
      &__body {
        padding-block-start: var(--spacing-xs);
      }
    }
  }
}
