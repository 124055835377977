@import '@we-make-websites/styles-lib/src/config/configuration';
.quickShopTray {
  $parent: &;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    border-bottom: 1px solid var(--color-border-dividers);
    display: grid;
    gap: var(--spacing-s);
    grid-auto-flow: column;
    justify-content: start;
    padding-inline-end: var(--spacing-l);
    padding-inline-start: var(--spacing-l);
    padding-block-end: var(--spacing-xl);
  }

  &__headerImage {
    border-radius: 100%;

    &:global(.loading) {
      background-color: var(--color-light-grey);
      height: 25px;
      width: 25px;
    }
  }

  &__proOnly {
    background-color: var(--color-background-grey);
    height: 100%;
  }

  &__proOnlyTitle,
  &__noResults {
    padding-block-end: var(--spacing-l);
    padding-block-start: var(--spacing-l);
    margin-inline-end: var(--margin-mobile);
    margin-inline-start: var(--margin-mobile);
  }

  &__proOnlyTitle {
    border-bottom: 1px solid var(--color-border-dividers);
  }

  &__list {
    padding-inline-end: var(--spacing-l);
    padding-inline-start: var(--spacing-l);

    #{$parent}__product:not(:last-child) {
      border-bottom: 1px solid var(--color-border-dividers);
    }
  }

  &__proOnly &__list &__product {
    border-bottom: 1px solid var(--color-border-dividers);
  }

  &__product {
    padding-block-end: var(--spacing-xl);
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}
