@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Color Selector Tab Component
 * -----------------------------------------------------------------------------
 * Base styles for the Color Selector Tab component.
 *
 */
.colorSelector {
  $parent: &;
  height: auto;
  width: 100%;
  position: relative;

  &__header {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    justify-content: space-between;
    margin-block-end: var(--spacing-m);
    margin-block-start: 0;
    margin-inline-end: auto;
    margin-inline-start: auto;
    white-space: nowrap;
  }

  h2:global(.is-loading) {
    @include loading();
    height: 38px;
    width: 250px;
    overflow: hidden;
  }

  &__controlsWrapper {
    display: flex;
    gap: var(--spacing-l);
    justify-content: flex-start;
    overflow: auto;
    margin-inline-start: calc(-1 * var(--margin-mobile));
    padding-inline-start: var(--margin-mobile);
    width: calc(100% + 2 * var(--margin-mobile));
  }

  &__controls {
    display: flex;
    gap: var(--spacing-l);
    justify-content: flex-start;
    margin-inline-start: calc(-1 * var(--margin-mobile));
    padding-inline-end: var(--margin-mobile);
    padding-inline-start: var(--margin-mobile);
  }

  &__control {
    @include button-reset;
    position: relative;
    overflow: hidden;
    padding-block-end: var(--spacing-xs);
    text-decoration: none;

    &:global(:not(.is-loading)):after {
      content: '';
      display: block;
      position: absolute;
      bottom: 4px;
      left: 0;
      height: 1px;
      width: 0;
      background: transparent;
      transition: width 0.5s ease, background-color 0.5s ease, left 0.5s ease;
    }

    &:hover:after,
    &:global(.isActive):after {
      width: 100%;
      background: var(--color-border-dark);
    }

    &:global(.is-loading) {
      @include loading();
      height: 18px;
      width: 100px;
      overflow: hidden;
    }
  }

  @include mq($until: m) {
    &__wrapper {
      margin-block-end: var(--spacing-xl);
      margin-block-start: var(--spacing-xl);
    }
  }

  @include mq($from: m) {
    &__wrapper {
      margin-block-end: var(--spacing-5xl);
      margin-block-start: var(--spacing-5xl);
    }

    &__title:global(.is-loading) {
      height: 38px;
    }

    &__controls {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 25px;
    }
  }

  @include mq($from: xl) {
    &__header {
      align-items: center;
      flex-direction: row;
      column-gap: var(--spacing-7xl);
      margin-block-end: var(--spacing-l);
      margin-block-start: 0;
    }

    &__controls {
      gap: var(--spacing-xl);
      justify-content: end;
    }
  }
}
