/**
 * Styles: Search menu overlay
 * -----------------------------------------------------------------------------
 * Override styles for the search menu overlay.
 *
 */
.searchMenuOverlay {
  top: var(--header-height-visible);
  z-index: initial;

  &[data-reach-dialog-overlay] {
    align-items: start;
  }

  .overlay {
    &__container {
      @include container;
      padding-block-start: var(--spacing-m);
    }

    &__body {
      margin-top: 0;
    }
  }

  @include mq($from: l) {
    .overlay {
      margin: 0;

      &__container {
        padding: var(--spacing-2xl) var(--spacing-m);
      }

      &__close {
        display: none;
      }
    }

    .modal {
      &[data-reach-dialog-content] {
        max-width: 100%;
      }
    }
  }
}
